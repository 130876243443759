import React from 'react'
import { auctionSold } from '../../../site/urls'
import SearchResultsAuctionTemplate from '../../../templates/search-result-auction-template'

const PropertyAuctionSold = (props) => {
    const location = props.location
  return (
    <SearchResultsAuctionTemplate
    locationName={auctionSold}
    location={location}
    pCategoryType="auction"
    pType="sales"
    pSubPath="sold"
    pStatus="Sold"
  />
  )
}

export default PropertyAuctionSold